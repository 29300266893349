
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddModal from "@/views/app/AddModal.vue";
import FilterDropdown from "@/views/app/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ApiApp } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

interface ApiData {
  id: number;
  status: number;
  name: string;
  type: number;
  short_key: string;
  frontend_base_url: string;
  rest_api_url: string;
  rpc_namespace: string;
  overall_notes: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

interface SortOption {
  field: string;
  direction: string;
}

export default defineComponent({
  name: "app-index",
  components: {
    MBDatatable,
    AddModal,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(true);
    const tableData = ref<Array<ApiData>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([]);
    const { showServerErrorMsg } = mixin();
    const selectData = ref([]);

    const tableHeader = ref([
      {
        name: t("permissions.name"),
        key: "name",
        sortable: true,
      },
      {
        name: t("permissions.shortKey"),
        key: "short_key",
        sortable: true,
      },
      {
        name: t("permissions.frontendBaseUrl"),
        key: "frontend_base_url",
        sortable: false,
      },
      {
        name: t("common.status"),
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("permissions.appManagement"), [
        { name: t("permissions.permissions"), path: "" },
      ]);
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    });

    const getTableList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiApp.getAppList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // TODO app type 获取接口
    // const getAppTypeOptions = () => {
    //   ApiBase.getTaggingData({ short_key: "global_app_type" }) // TODO 换接口
    //     .then(({ data }) => {
    //       if (data.code == 0) {
    //         selectData.value = data.data.items;
    //       } else {
    //         showServerErrorMsg(data);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // };

    const updateList = () => {
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getTableList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getTableList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      const DataEnums = {
        status: { field: "status", condition: "eq" },
        name: { field: "name", condition: "like" },
        type: { field: "type", condition: "eq" },
        short_key: { field: "short_key", condition: "like" },
        frontend_base_url: { field: "frontend_base_url", condition: "like" },
        rest_api_url: { field: "rest_api_url", condition: "like" },
        rpc_namespace: { field: "rpc_namespace", condition: "like" },
        overall_notes: { field: "overall_notes", condition: "like" },
      };
      for (let item in val) {
        if (val[item] != "") {
          filterArr.push({
            field: DataEnums[item].field,
            value: val[item],
            condition: DataEnums[item].condition,
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getTableList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    return {
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      selectData,
      getTableList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
    };
  },
});
